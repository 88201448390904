<template>
  <div v-if="!isReadonly">
    <div class="d-flex align-items-center justify-content-md-between justify-content-center flex-wrap"
     style="overflow:hidden; margin:auto;">
      <div class="mb-2">
        <div id="recaptcha-main" class="recaptcha" data-callback="myRecaptchaMethod" data-sitekey="6LfwjfYkAAAAADFGaaRxaVFfHBbfG_caeuqLKX90"></div>
        <label class="error_message text-start" v-if="hasErrors"> {{hasErrors?.errorMessage}} </label>
      </div>
      <button v-if="cancelField"
              class="btn bg-gradient-light"
              @click.prevent="onCancel"
              :disabled="submitStatus"> {{ cancelField?.help }} </button>

      <button type="submit"
      style="max-width: 230px"
              @click.prevent="submit"
              :disabled="submitStatus"
              :class="submitField?.extra_parameters?.styles || submitField?.extra_parameters?.btn_styles || 'btn btn-success'">
        <span v-if="submitStatus" class="spinner-border text-light spinner-border-sm mx-1" role="status"></span>
        {{ submitField?.help }}
      </button>
    </div>

    <div class="d-flex justify-content-end" v-if="submitField?.extra_parameters?.help_text">
      <span class=""> {{ submitField.extra_parameters.help_text }} </span>
    </div>
  </div>
</template>
<script>
// Store
import { dynamicFormStore } from "../../stores/store";
import { mapStores, mapState } from 'pinia'

export default {
  inject: ['isReadonly'],

  props: {
    hasErrors: {
      type: Object
    },
  },

  computed: {
    ...mapStores(dynamicFormStore),
    ...mapState(dynamicFormStore, ['submitField', 'submitStatus', 'cancelField'])
  },

  mounted(){
    // Assign data-callback to a method
    window.myRecaptchaMethod = this.myRecaptchaMethod;
  },
  created(){
    setTimeout(() => {
        grecaptcha.render('recaptcha-main'); // 'recaptcha-main' is the id that was assigned to the widget
    }, 1000);
  },

  methods: {
    myRecaptchaMethod: function(response) {
      this.$emit('deleteCaptchaError')
    },
    submit() {
      let response = grecaptcha.getResponse();
      this.$emit('submit', response)
    },

    onCancel() {
      this.$emit('onCancel')
    },
  },
}
</script>

<style>
.error_message {
  color: red;
}
@media screen and (max-width:475px) {
  .recaptcha {
    transform: scale(.82);
  }
}
</style>